<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Program </span>


                <v-btn color="white" v-if="!isLoading" class="px-5 mx-5 button" v-on="on" @click="synchLearner()"
                    style="margin: 2px; float: right">synchronize program from ERP</v-btn>

                <v-dialog persistent v-model="isLoading">
                    <v-row justify="center">
                        <v-card width="400" height="100">
                            <center class="pa-5">
                                Data Importing Form ERP ..<br />
                                Please Wait It will Take Time..
                            </center>
                        </v-card>
                    </v-row>
                </v-dialog>


            </v-card-title>
            <div v-if="init_loading">
                <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
            </div>
            <div v-else>
                <v-card-text>
                    <div class="add-section">
                        <div style="text-align: center;">
                            <v-col>
                                <v-form ref="form">
                                    <label class="add-text">Add New Program</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="program" outlined dense :rules="[rules.required]"
                                        clearable required></v-text-field>
                                    <label class="add-text">Select Program Type</label><span style="color: red;"> *</span>
                                    <v-autocomplete class="text" :rules="[rules.required]" item-text="name" item-value="id"
                                        :items="programtypelist" v-model="pt" dense outlined></v-autocomplete>
                                    <v-card-text style="padding-bottom: 0px;">
                                        <label class="add-text">Display Order</label><span style="color: red;"> *</span>
                                        <v-text-field class="text" v-model="display_order" type="number" :min="0" outlined
                                            dense :rules="[rules.required]" required></v-text-field>
                                    </v-card-text>
                                    <v-card-text style="padding-bottom: 0px;">
                                        <label class="add-text">Short Name</label>
                                        <v-text-field class="text" v-model="short_name" outlined dense
                                            clearable></v-text-field>
                                    </v-card-text>
                                    <v-btn color="success" @click="addprogram">Add</v-btn>
                                </v-form>
                            </v-col>
                        </div>
                    </div>

                    <template>
                        <div class="text-center">
                            <v-dialog v-model="dialog" width="500">
                                <v-card>
                                    <v-card-title class="grey lighten-2">
                                        Edit Program
                                    </v-card-title>

                                    <v-card-text style="padding-bottom: 0px;">
                                        <label class="add-text">Program</label><span style="color: red;">
                                            *</span>
                                        <v-text-field class="text" v-model="programName" outlined dense
                                            :rules="[rules.required]" clearable required></v-text-field>
                                        <label class="add-text">Select Program Type</label><span style="color: red;">
                                            *</span>
                                        <v-autocomplete class="text" :rules="[rules.required]" item-text="name"
                                            item-value="id" :items="programtypelist" v-model="pt" dense
                                            outlined></v-autocomplete>
                                    </v-card-text>
                                    <v-card-text style="padding-bottom: 0px;">
                                        <label class="add-text">Display Order</label><span style="color: red;">
                                            *</span>
                                        <v-text-field class="text" v-model="display_order" outlined dense type="number"
                                            :min="0" :rules="[rules.required]" required></v-text-field>
                                    </v-card-text>
                                    <v-card-text style="padding-bottom: 0px;">
                                        <label class="add-text">Short Name</label>
                                        <v-text-field class="text" v-model="short_name" outlined dense
                                            :rules="[rules.required]" clearable required></v-text-field>
                                    </v-card-text>

                                    <!-- <v-divider></v-divider> -->

                                    <div class="text-center">
                                        <v-spacer></v-spacer>
                                        <v-btn class="m-3" @click="dialog = false">
                                            Close
                                        </v-btn>
                                        <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                    </div>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>

                    <template v-if="programlist != null">
                        <v-data-table :headers="headers" :items="programlist" :search="search" class="elevation-1 nowrap">
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table"
                                        single-line hide-details></v-text-field>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.edit="{ item }">
                                <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                                <v-icon v-if="item" class="mr-2" @click="updatecurrency(item)">mdi mdi-pencil-box</v-icon>
                            </template>
                            <!--
                          <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteprogram(item)"> mdi mdi-delete</v-icon>
                        </template>
                        -->

                        </v-data-table>
                    </template>
                </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        init_loading: false,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        org: null,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Program',
                disabled: true,
                href: '',
            },
        ],
        dialog: false,
        search: "",
        program: null,
        pt: null,
        programId: null,
        programtypelist: null,
        display_order: null,
        short_name: null,
        programName: null,
        programtName: null,
        userType: null,
        edit_btn_load: false,
        isLoading: false,
        rules: {
            required: (value) => !!value || "Required.",
        },
        program_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Program", value: "program" },
            { text: "Short Name", value: "short_name" },
            { text: "Edit", value: "edit" },
            { text: "Program Type", value: "program_type" },
        ],
    }),
    watch: {},
    methods: {
        synchLearner() {
            this.isLoading = true;
            axios
                .post("/ImportData/synchprog")
                .then((res) => {
                    if (res.data.status.code == "SUCCESS") {
                        this.showSnackbar(
                            "#4caf50",
                            "Learner Synchronized Successfully..!"
                        );
                        this.isLoading = false;
                        this.onLoad();
                    } else if (res.data.status.code == "NA") {
                        this.showSnackbar("#b71c1c", "Error While Synchronization");
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                    this.isLoading = false;
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {
            console.log(item);
            axios
                .post("/admin/currencyIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Program  updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },
        updatecurrency(item) {
            this.dialog = true;
            this.programName = item.program;
            this.programId = item.programId;
            this.pt = item.program_type_id;
            this.display_order = item.display_order;
            this.short_name = item.short_name;
        },
        saveEdit(item) {
            if (this.programName) {
                this.programName = this.programName.trim()
                this.short_name
            }
            console.log(item);
            const data = {
                programName: this.programName,
                programId: this.programId,
                program_type: this.pt,
                short_name: this.short_name,
                display_order: this.display_order,
            };
            if (this.programName) {
                axios
                    .post("/admin/editProgram", data)
                    .then((res) => {
                        if (res.data.msg == "success") {
                            this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Program  updated successfully...");
                            this.onload();
                            this.dialog = false;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
            } else {
                this.showSnackbar("#b71c1c", "Please enter Program");
            }
        },
        deleteprogram(item) {
            const data = {
                program: item.program,
            };
            axios
                .post("/admin/deleteprogram", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Program Deleted Successfully  !!");
                        this.onload();
                    }

                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },
        onload() {
            this.init_loading = true
            axios
                .post("/admin/getProgram")
                .then((res) => {
                    this.org = res.data.org;
                    this.programlist = res.data.program;
                    this.programtypelist = res.data.programtypelist;
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addprogram() {
            if (this.program) {
                this.program = this.program.trim()
            }
            const data = {
                program_name: this.program,
                program_type: this.pt,
                display_order: this.display_order,
                short_name: this.short_name,
            };

            if (this.program && this.pt && this.display_order) {
                axios
                    .post("/admin/addProgram", data)
                    .then((res) => {
                        this.org = res.data.org;
                        this.onload();
                        if (res.data.msg == "success") {
                            this.showSnackbar("#4CAF50", "Program  added successfully...");
                            this.userType = null;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { });
            } else {
                this.showSnackbar("#b71c1c", "Please enter Program and Program Type and Display Order");
                this.$refs.form.validate()
            }
        },
    },
    mounted() {
        this.onload();
    },
};
</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
